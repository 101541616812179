/*
 * @Date: 2022-06-24 09:24:30
 * @descriotion:
 */
import { ATTENDACE } from '../api'
import { METHOD, request } from '@/utils/request'

// 下载导入模板
export async function getImportTemplate(params) {
  return request(`${ATTENDACE}/template/getImportTemplate`, METHOD.GET, params, { responseType: 'blob', headers: { isDownload: true }})
}

// 下载排班导入模板
export async function shiftGetImportTemplate(params) {
  return request(`${ATTENDACE}/shift/getImportTemplate`, METHOD.GET, params, { responseType: 'blob', headers: { isDownload: true }})
}

// 批量导入打卡记录
export async function batchImportClock(matchRule, params) {
  return request(`${ATTENDACE}/clock/batchImport?matchRule=${matchRule}`, METHOD.POST, params)
}

// 批量导入补卡记录
export async function batchImportApplyAppendPunch(matchRule, params) {
  return request(`${ATTENDACE}/applyAppendPunch/batchImport?matchRule=${matchRule}`, METHOD.POST, params)
}

// 批量导入请假记录
export async function batchImportApplyVacation(matchRule, params) {
  return request(`${ATTENDACE}/applyVacation/batchImport?matchRule=${matchRule}`, METHOD.POST, params)
}

// 批量导入外出记录
export async function batchImportApplyOutside(matchRule, params) {
  return request(`${ATTENDACE}/applyOutside/batchImport?matchRule=${matchRule}`, METHOD.POST, params)
}

// 批量导入出差记录
export async function batchImportApplyTravel(matchRule, params) {
  return request(`${ATTENDACE}/applyTravel/batchImport?matchRule=${matchRule}`, METHOD.POST, params)
}

// 批量导入加班记录
export async function batchImportApplyOvertime(matchRule, params) {
  return request(`${ATTENDACE}/applyOvertime/batchImport?matchRule=${matchRule}`, METHOD.POST, params)
}

// 批量导入员工排班表数据
export async function batchImportShift(matchRule, params) {
  return request(`${ATTENDACE}/shift/batchImport?matchRule=${matchRule}`, METHOD.POST, params)
}

// 批量发放假期
export async function batchImportGrantVacationAdjustment(matchRule, params) {
  return request(`${ATTENDACE}/vacationAdjustment/batchImportGrant?matchRule=${matchRule}`, METHOD.POST, params)
}

// 批量扣减假期
export async function batchImportDeductVacationAdjustment(matchRule, params) {
  return request(`${ATTENDACE}/vacationAdjustment/batchImportDeduct?matchRule=${matchRule}`, METHOD.POST, params)
}

// 批量导入添加调班
export async function batchImportChangeApi(matchRule, params) {
  return request(`${ATTENDACE}/applyChangeShift/batchImportChange?matchRule=${matchRule}`, METHOD.POST, params)
}
// 批量导入添加换班
export async function batchImportExchangeApi(matchRule, params) {
  return request(`${ATTENDACE}/applyChangeShift/batchImportExchange?matchRule=${matchRule}`, METHOD.POST, params)
}

// 批量导入添加换班
export async function getCurrentPeriodApi(params) {
  return request(`${ATTENDACE}/config/period/getCurrentPeriod`, METHOD.GET, params)
}
